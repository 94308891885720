export default {
	initLogin(ctx, payload) {
		ctx.commit("SET_AUTHENTICATED", true);
		ctx.commit("SET_USER", payload.userId);
		ctx.commit("SET_TOKEN", payload.accessToken);
		ctx.commit("SET_TOKEN_EXPIRATION", payload.expirationTime);
	},
	setAuthProvider(ctx, payload) {
		ctx.commit("SET_AUTH_PROVIDER", payload);
	},
	setAuthenticated(ctx, payload) {
		ctx.commit("SET_AUTHENTICATED", payload);
	},
	setUser(ctx, payload) {
		ctx.commit("SET_USER", payload);
	},
	setToken(ctx, payload) {
		ctx.commit("SET_TOKEN", payload);
	},
	setTokenExpiration(ctx, payload) {
		ctx.commit("SET_TOKEN_EXPIRATION", payload);
	},
	// setPauseAuth(ctx, payload) {
	// 	ctx.commit("SET_PAUSE_AUTH", payload);
	// },
	// setAuthError(ctx, payload) {
	// 	ctx.commit("SET_AUTH_ERROR", payload);
	// },
	// setContext(ctx, payload) {
	// 	ctx.commit("SET_CONTEXT", payload);
	// },
	// setLoginPopup(ctx, payload) {
	// 	ctx.commit("SET_LOGIN_POPUP", payload);
	// },
	// setReturnUrl(ctx, payload) {
	// 	ctx.commit("SET_RETURN_URL", payload);
	// },
	// setRefreshTimer(ctx, payload) {
	// 	ctx.commit("SET_REFRESH_TIMER", payload);
	// },
	// setTenantId(ctx, payload) {
	// 	ctx.commit("SET_TENANT_ID", payload);
	// },
	clearSession(ctx) {
		// ctx.commit("SET_TENANT_ID", "");
		// ctx.commit("SET_CONTEXT", null);
		ctx.commit("SET_USER", null);
		ctx.commit("SET_AUTH_PROVIDER", "");
		ctx.commit("SET_AUTHENTICATED", false);
		ctx.commit("SET_TOKEN", "");
		// ctx.commit("SET_TOKEN_EXPIRATION", null);
		// ctx.commit("SET_PAUSE_AUTH", false);
		// ctx.commit("SET_LOGIN_POPUP", false);
	},
	// log(ctx, payload) {
	// 	ctx.commit("LOG", payload);
	// },
};
