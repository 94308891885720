<template>
  <v-row>
    <v-col cols="12" lg="8" class="px-6">
      <h1 class="welcome">Loading...</h1>
      <v-row>
        <v-col>
          <v-skeleton-loader class="theme__border" type="table" />
        </v-col>
      </v-row>
    </v-col>
    <v-col class="p-0 bg-white theme__border__left" style="min-height: 100vh">
      <v-skeleton-loader
        type="list-item, divider, list-item-avatar-two-line, divider, list-item-avatar-two-line, divider, list-item-avatar-two-line, divider"
      />
      <v-skeleton-loader
        type="list-item, divider, list-item-avatar-two-line, divider, list-item-avatar-two-line, divider, list-item-avatar-two-line"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  @include margin(16px 4px);
}
</style>