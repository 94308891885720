<template>
  <div class="theme__list-item updates">
    <div
      class="icon-wrapper"
      :class="issue ? 'red darken-2' : ''"
      :style="!issue ? 'background-color: #26C1C9' : ''"
    >
      <v-icon :color="color">{{icon}}</v-icon>
      <!-- <font-awesome-icon :icon="icon"></font-awesome-icon> -->
    </div>
    <div style="flex-grow: 1">
      <router-link :to="link">{{name}}</router-link>
      <div style="white-space: nowrap;">
        <span>{{campaign}}</span>
        <span style="float:right;">{{$helpers.displayTime(date)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateItem",
  props: {
    icon: { type: String, default: "fa-clipboard-list" },
    color: { type: String, default: "white" },
    bgcolor: { type: String, default: "#26C1C9" },
    issue: { type: Boolean, default: false },
    name: { type: String, default: "..." },
    link: { type: String, default: "#" },
    campaign: { type: String, default: "" },
    date: { type: Date, default: new Date() },
  },
};
</script>