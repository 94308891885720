export default {
	apiUrl: "https://efundraisingapi-test.azurewebsites.net/api/v1",
	//apiUrl: "https://api.dashtech.app/api/v1",

	// redirectUri: "http://localhost:8080/",
	//redirectUri: "https://dashtechdev.z5.web.core.windows.net/",
	//redirectUri: "https://project.dashtech.app/",

	// clientId: "a342fb1a-9e1b-4d30-a9d3-e39a0ae0e967",
	//sclientId: "a2b8ae0f-282b-4d14-981e-825408371772",
};
