export default {
	// SET
	SET_AUTH_PROVIDER(state, payload) {
		state.authProvider = payload;
		localStorage.setItem("authProvider", payload);
	},
	SET_AUTHENTICATED(state, payload) {
		state.authenticated = payload;
		localStorage.setItem("authenticated", payload);
	},
	SET_USER(state, payload) {
		state.user = payload;
		localStorage.setItem("user", JSON.stringify(payload));
	},
	SET_TOKEN(state, payload) {
		state.token = payload;
		localStorage.setItem("token", JSON.stringify(payload));
	},
	SET_TOKEN_EXPIRATION(state, payload) {
		state.tokenExpiration = payload;
		localStorage.setItem("tokenExpiration", JSON.stringify(payload));
	},
	// SET_PAUSE_AUTH(state, payload) {
	// 	state.pauseAuth = payload;
	// 	localStorage.setItem("pauseAuth", payload);
	// },
	// SET_AUTH_ERROR(state, payload) {
	// 	state.authError = payload;
	// 	localStorage.setItem("authError", payload);
	// 	if (payload !== "") {
	// 		let date = new Date();
	// 		state.authErrorTimer = date;
	// 		localStorage.setItem("authErrorTimer", JSON.stringify(date));
	// 	} else {
	// 		state.authErrorTimer = null;
	// 		localStorage.setItem("authErrorTimer", null);
	// 	}
	// },
	// SET_CONTEXT(state, payload) {
	// 	state.context = payload;
	// 	localStorage.setItem("context", JSON.stringify(payload));
	// },
	// SET_LOGIN_POPUP(state, payload) {
	// 	state.loginPopup = payload;
	// 	localStorage.setItem("loginPopup", payload);
	// },
	// SET_RETURN_URL(state, payload) {
	// 	state.url = payload;
	// 	localStorage.setItem("returnUrl", payload);
	// },
	// SET_REFRESH_TIMER(state, payload) {
	// 	state.refreshTimer = payload;
	// 	localStorage.setItem("refreshTimer", JSON.stringify(payload));
	// },
	// SET_TENANT_ID(state, payload) {
	// 	state.tenantId = payload;
	// 	localStorage.setItem("tenantId", payload);
	// },
	// LOG(state, payload) {
	// 	let entry = {
	// 		eventTime: new Date(),
	// 		message: payload,
	// 	};
	// 	state.log.push(entry);
	// 	localStorage.setItem("log", JSON.stringify(state.log));
	// },
};
