<template>
  <v-app-bar class="nav-bar" color="#5D9B64" height="60px" dark app clipped-left flat>
    <v-app-bar-nav-icon @click="$store.commit('app/SET_DRAWER_STATE', !toggle)" />
    <v-toolbar-title to="/">
      <img src="@/assets/efund-full-logo.png" alt="eFundraising" />
    </v-toolbar-title>
    <v-spacer />

    <!-- <div style="width: 250px" class="mx-2">
      <v-text-field
        class="theme-search"
        label="Search"
        background-color="rgba(255,255,255, 0.07)"
        prepend-inner-icon="mdi-magnify"
        solo
        dark
        flat
        clearable
        dense
        hide-details
      ></v-text-field>
    </div>-->
    <v-btn
      dark
      style="font-size: 10pt; border: 1px solid rgba(0,0,0,.05) !important;"
      color="primary"
      elevation="0"
      class="mx-2"
    >
      <v-icon>mdi-plus</v-icon>New Account
    </v-btn>

    <!-- <v-badge :dot="notifications" overlap color="red" class="mx-2">
      <v-avatar size="40">
        <img src="@/assets/default-profile-image.png" alt="Your Profile" />
      </v-avatar>
    </v-badge>-->

    <!-- <v-btn class="mx-2" icon>
      <v-icon>fa-cog</v-icon>
    </v-btn>-->
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      notifications: true,
    };
  },
  methods: {
    toggleSidebar() {
      this.$parent.toggleSidebar();
    },
  },
  computed: {
    ...mapGetters({
      toggle: "app/getDrawerState",
    }),
  },
};
</script>

<style lang="scss">
.theme-search {
  border: 1px solid rgba(255, 255, 255, 0.15);
}
</style>