import Vuex from "vuex";
import app from "./modules/app";
import auth from "./modules/auth";
import trans from "./modules/trans";

const store = () =>
	new Vuex.Store({
		modules: {
			app,
			auth,
			trans,
		},
	});

export default store;
