<template>
  <div>
    <div>
      <h2
        class="label"
        style="padding: 10px 20px; margin: 0;background-color: #f9f9f9; border-top: 1px solid #D8E5EE;"
      >Recent Activity</h2>
    </div>
    <div v-for="(activity,i) in activitiesFiltered" :key="i">
      <ActivityListItem
        :transId="activity.transactionId"
        :donor="activity.activityTitle"
        :campaign="activity.accountName"
        :date="new Date(activity.date)"
        :canVoid="activity.canVoid"
        :canRefund="activity.canRefund"
      />
    </div>
    <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-card-title class="headline">Are you sure you would like to {{text}} this order?</v-card-title>

        <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false">Close</v-btn>
          <v-btn color="red darken-1" text @click="quickChange(text)">{{text}} Label</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <div class="view-more" v-if="maxListings<activities.length">
      <router-link to>View More</router-link>
    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import ActivityListItem from "@/components/list-components/ActivityListItem.vue";

export default {
  name: "RecentActivity",
  components: { ActivityListItem },
  name: "RecentActivity",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    maxListings: { type: Number, default: 10 },
  },
  data() {
    return {
      dialog: false,
      text: "",
      transId: null,
    };
  },
  methods: {
    setDialog(bool, text, id) {
      this.dialog = bool;
      this.text = text;
      this.transId = id;
    },
    quickChange(text) {
      if (text == "Void") {
        let url = `${this.$api_url}/admin/transactions/${this.transId}/void-and-delete`;
        axios
          .post(
            url,
            {},
            this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
          )
          .then((res) => {
            if (res.status == 204) {
              // console.log(res);
              this.$emit("voided", this.transId);
            }
            this.dialog = false;
          })
          .catch((err) => console.log(err.response));
      }
      if (text == "Refund") {
        let url = `${this.$api_url}/admin/transactions/${this.transId}/refund`;
        axios
          .post(
            url,
            {},
            this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
          )
          .then((res) => {
            if (res.status == 204) {
              // console.log(res);
              this.$emit("voided", this.transId);
            }
            this.dialog = false;
          })
          .catch((err) => console.log(err.response));
      }
    },
  },
  computed: {
    activitiesFiltered() {
      return this.items.slice(0, this.maxListings);
    },
  },
};
</script>

