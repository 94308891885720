<template>
  <div class="striped">
    <div>
      <h2
        class="label"
        style="padding: 10px 20px; margin: 0; border-top: 1px solid #D8E5EE;"
      >New Updates from eFund</h2>
    </div>
    <div v-for="(update,i) in updatesFiltered" :key="i">
      <UpdateItem :name="update.name" :campaign="update.campaign" :date="update.date" />
    </div>
    <div class="view-more" v-if="maxListings<updates.length">
      <router-link to>View More</router-link>
    </div>
  </div>
</template>

<script>
import UpdateItem from "@/components/list-components/UpdateItem.vue";

export default {
  components: { UpdateItem },
  name: "Updates",
  props: {
    maxListings: { type: Number, default: 5 },
  },
  data() {
    return {
      updates: [
        {
          name: "Recieve donations by Apple Pay",
          campaign: "eFundraising",
          date: new Date(2021, 0, 4, 16, 30, 0),
        },
        {
          name: "New landing page template designs",
          campaign: "eFundraising",
          date: new Date(2021, 0, 4, 15, 15, 0),
        },
        {
          name: "New features released",
          campaign: "eFundraising",
          date: new Date(2021, 0, 4, 15, 15, 0),
        },
      ],
    };
  },
  computed: {
    updatesFiltered() {
      return this.updates.slice(0, this.maxListings);
    },
  },
};
</script>

