import axios from "axios";
import { store } from "@/store";
import appConfig from "@/config";

export function getReqConfig(token, provider, tenant, additionalHeaders) {
	let headers = {};

	if (provider) {
		headers["auth-provider"] = provider;
	}
	if (token) {
		headers["Authorization"] = `Bearer ${token}`;
	}
	if (tenant) {
		headers["tenant-id"] = tenant;
	}

	let config = {
		headers: headers,
	};
	if (additionalHeaders != null) {
		config.headers = { ...additionalHeaders, ...config.headers };
	}

	return config;
}
