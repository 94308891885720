<template>
  <v-container fluid>
    <v-fade-transition mode="out-in">
      <SkeletonLoader v-if="loading" key="loading" />

      <v-row v-if="!loading && activeCampaigns.length" key="page">
        <v-col cols="12" lg="8" class="px-6">
          <h1 class="welcome">Welcome {{name}}!</h1>

          <v-row>
            <v-col>
              <ActiveCampaigns :items="activeCampaigns" class="mx-0"></ActiveCampaigns>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <MetricCard
                label="Total Donations"
                icon="flag"
                color="#AB7DF6"
                :value="donationCount.value"
                :prevValue="donationCount.changeRate"
                prevValuePercentage
                displayMonthCompare
                displayAsMoney
              />
            </v-col>
            <v-col>
              <MetricCard
                label="Top Donation"
                icon="trophy"
                color="#81C926"
                :value="topDonation.value"
                :prevValue="topDonation.changeRate"
                prevValuePercentage
                displayMonthCompare
                displayAsMoney
              />
            </v-col>
            <v-col>
              <MetricCard
                label="Avg. Donation Size"
                icon="money-check-alt"
                color="#26C1C9"
                :value="avgDonation.value"
                :prevValue="avgDonation.changeRate"
                prevValuePercentage
                displayMonthCompare
                displayAsMoney
              />
            </v-col>
          </v-row>
          <!-- <div class="flex" style="flex-wrap: wrap; justify-content: center">
          <MetricCard
            label="Total Donations"
            icon="flag"
            color="#AB7DF6"
            :value="3500"
            :prevValue="3000"
            displayMonthCompare
            displayAsMoney
          ></MetricCard>
          <MetricCard
            label="Top Donation"
            icon="trophy"
            color="#81C926"
            :value="3500"
            :prevValue="3043"
            displayMonthCompare
            displayAsMoney
          ></MetricCard>
          <MetricCard
            label="Average Donation Size"
            icon="money-check-alt"
            color="#26C1C9"
            :value="18.75"
            :prevValue="17.60"
            displayMonthCompare
            displayAsMoney
          ></MetricCard>
          </div>-->
          <!-- <div class="flex" style="justify-content: space-evenly;  flex-wrap: wrap">
          <PieChart title="Payment Method" :chartData="this.methodData"></PieChart>
          <PieChart title="Payment Status" :chartData="statusData"></PieChart>
          </div>-->
        </v-col>
        <DashboardSidebar :recentActivity="recentActivity" @voided="removeTransactions" />
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import SkeletonLoader from "@/components/layout/SkeletonLoader.vue";
import DashboardSidebar from "@/components/layout/DashboardSidebar.vue";
import MetricCard from "@/components/cards/MetricCard.vue";
import ActiveCampaigns from "@/components/sub-components/ActiveCampaigns.vue";
import PieChart from "@/components/sub-components/PieChart.vue";

import axios from "axios";

export default {
  name: "TreasurerDashboard",
  components: {
    SkeletonLoader,
    MetricCard,
    ActiveCampaigns,
    PieChart,
    DashboardSidebar,
  },
  data() {
    return {
      name: "Jorge",
      loading: null,
      avgDonation: { value: 0 },
      donationCount: { value: 0 },
      topDonation: { value: 0 },
      activeCampaigns: [],
      recentActivity: [],
      // methodData: [
      //   ["Payment Type", "Amount"],
      //   ["Paypal/CashApp/Venmo", 300],
      //   ["Credit Card", 800],
      //   ["Apple Pay", 2000],
      //   ["Checks", 250],
      // ],
      // statusData: [
      //   ["Status", "Amount"],
      //   ["Pending", 2300],
      //   ["New", 250],
      //   ["Disbursed", 800],
      // ],
    };
  },
  mounted() {
    this.loading = true;

    if (!this.$store.getters["auth/getAuthenticated"]) {
      this.login();
    } else {
      this.setupDashboard();
    }
  },
  methods: {
    login() {
      let loginUrl = `${this.$api_url}/admin/login`;
      let permUrl = `${this.$api_url}/admin/users/${this.$route.query.user}/permissions`;

      let req = {
        Username: "smahamud@capitoltechsolutions.com",
        Password: "Test~~1",
      };

      axios.post(loginUrl, req).then((res) => {
        this.$store.dispatch("auth/initLogin", res.data);
        console.log(res);
        this.setupDashboard();
      });
      axios
        .post(permUrl, req)
        .then((res) => {
          // this.$store.dispatch("auth/initLogin", res.data);
          // console.log(res);
          // this.setupDashboard();
        })
        .catch((err) => {
          // console.log(err.response);
          if (401 == err.response.status) {
            // window.location.href = "https://www.google.com";
          }
        });
    },
    setupDashboard() {
      let url = `${this.$api_url}/admin/users/${this.$store.getters["auth/getUser"]}/treasurer-dashboard`;
      axios
        .get(url, this.$auth.getReqConfig(this.$store.getters["auth/getToken"]))
        .then((res) => {
          console.log(res.data);

          this.name = res.data.treasurerName;
          this.avgDonation = res.data.avgDonation;
          this.donationCount = res.data.donationCount;
          this.topDonation = res.data.topDonation;
          this.activeCampaigns = res.data.campaigns;
          this.recentActivity = res.data.recentActivity;
          this.loading = false;
        })
        .catch((err) => {
          if (401 == err.response.status) {
            this.$store.dispatch("auth/clearSession");
            this.login();
          }
          console.log("Error when trying to get data", err.response);
        });
    },
    removeTransactions(id) {
      this.setupDashboard();
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  @include margin(16px 4px);
}
</style>
