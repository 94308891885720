import Home from "@/pages/Home.vue";
// import Sales from "@/pages/Sales.vue";
import Campaign from "@/pages/Campaign.vue";
import TreasurerDashboard from "@/pages/TreasurerDashboard.vue";
import MemberDashboard from "@/pages/MemberDashboard.vue";

export default {
	mode: "history",
	routes: [
		// {
		// 	path: "/",
		// 	name: "Home",
		// 	component: Home,
		// 	meta: { title: "Home | eFundraising" },
		// },
		{
			path: "/campaign",
			name: "Campaign",
			component: Campaign,
			meta: { title: "Campaign | eFundraising" },
		},
		{
			path: "/campaign/:id",
			name: "Campaign Detail",
			component: Campaign,
			meta: { title: "Campaign Detail | eFundraising" },
		},
		{
			path: "/",
			name: "Treasurer Dashboard",
			component: TreasurerDashboard,
			meta: { title: "Dashboard | eFundraising" },
		},
		{
			path: "/*",
			name: "Treasurer Dashboard",
			component: TreasurerDashboard,
			meta: { title: "Dashboard | eFundraising" },
		},
		// {
		// 	path: "/c-dashboard",
		// 	name: "Council Memeber Dashboard",
		// 	component: MemberDashboard,
		// 	meta: { title: "Dashboard | eFundraising" },
		// },
	],
};
