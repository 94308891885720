export default {
	getAuthProvider: (state) => state.authProvider,
	getAuthenticated: (state) => state.authenticated,
	getUser: (state) => state.user,
	getToken: (state) => state.token,
	// getTokenExpiration: (state) => state.tokenExpiration,
	// getPauseAuth: (state) => state.pauseAuth,
	// getAuthError(state) {
	// 	var now = new Date();
	// 	if (Math.abs(now - state.authErrorTimer) > 60 * 1000) {
	// 		state.authError = "";
	// 		localStorage.setItem("authError", "");
	// 		state.authErrorTimer = null;
	// 		localStorage.setItem("authErrorTimer", null);
	// 	}
	// 	return state.authError;
	// },
	// getContext: (state) => state.context,
	// getLoginPopup: (state) => state.loginPopup,
	// getReturnUrl: (state) => state.returnUrl,
	// getLog: (state) => state.log,
	// getRefreshTimer: (state) => state.refreshTimer,
	// getTenantId: (state) => state.tenantId,
};
