<template>
  <div class="p-0 card">
    <b-tabs class="section-tabs">
      <b-tab active :title="title">
        <div class="centered-overlay" v-if="showOverlay">
          <span class="value">{{$helpers.formatNumber(total(chartData))}}</span>
          <br />
          {{overlayLabel}}
        </div>
        <GChart type="PieChart" :data="chartData" :options="options" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
export default {
  name: "PieChart",
  components: {
    GChart,
  },
  props: {
    title: { type: String, default: "Payment Method" },
    overlayLabel: { type: String, default: "Donations" },
    showOverlay: { type: Boolean, default: true },
    chartData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      options: {
        pieHole: 0.75,
        width: 370,
        height: 170,
        colors: ["#26C1C9", "#FACA00", "#81C926", "#AB7DF6"],
        backgroundColor: {
          fill: "#f9f9f9",
          fillOpacity: 0,
        },
        chartArea: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 20,
          width: "100%",
          height: "100%",
        },
        legend: {
          position: "right",
          maxLines: "2",
          alignment: "center",
          textStyle: {
            color: "#8DABC4",
            fontName: "Heebo",
            fontSize: 12,
          },
        },
        pieSliceText: "none",
        tooltip: {
          isHtml: true,
        },
      },
    };
  },
  methods: {
    total(data) {
      var sum = 0;
      for (var i = 1; i < data.length; i++) {
        sum += data[i][1];
      }
      return sum;
    },
  },
};
</script>

<style scoped>
.centered-overlay {
  position: absolute;
  top: 145px;
  left: 33%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}
</style>