<template>
  <div class="theme__list-item activities">
    <div class="icon-wrapper">
      <font-awesome-icon :icon="icon">$</font-awesome-icon>
    </div>
    <div style="flex-grow: 1">
      <h3 class="text-truncate">{{donor}}</h3>
      <div class="truncate-first campaign">
        <div class="campaign__name text-truncate">{{campaign}}</div>
        <div class="campaign__links">
          <span class="ml-2">{{$helpers.displayTime(date)}}{{'\xa0'}}</span>
          <v-btn
            color="primary"
            @click="$parent.setDialog(true, 'Void', transId)"
            text
            small
            dense
          >Void</v-btn>
          <v-btn
            v-if="canRefund"
            color="primary"
            @click="$parent.setDialog(true, 'Refund')"
            text
            small
            dense
          >Refund</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityListItem",
  props: {
    transId: { type: Number, default: null },
    icon: { type: String, default: "dollar-sign" },
    donor: { type: String, default: "Anonymous" },
    campaign: { type: String, default: "" },
    date: { type: Date, default: new Date() },
    canVoid: { type: Boolean, default: false },
    canRefund: { type: Boolean, default: false },
  },
  methods: {},
  computed: {
    currentDate() {
      return new Date();
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.campaign {
    // width: 80%;
    // max-width: 500px;
    display: flex;
    min-width: 0;
    flex: 1 0 30%;

    @media #{map-get($display-breakpoints,"lg-and-up")} {
      // max-width: 130px;
    }
  }
  & .campaign {
    &__name {
      max-width: 150px;
    }
    &__links {
      flex-shrink: 0;
    }
  }
}
</style>

