let parseNonString = (item, alt) => {
	item = typeof item !== "string" ? JSON.stringify(item) : item;

	try {
		item = JSON.parse(item);
	} catch (e) {
		item = alt;
	}

	if (item == null) {
		item = alt;
	}

	return item;
};

let initTokenExpiration = () => {
	var time = null;
	if (localStorage.getItem("tokenExpiration")) {
		try {
			time = new Date(JSON.parse(localStorage.getItem("tokenExpiration")));
		} catch {}
	} else if (localStorage.getItem("expirationTime")) {
		try {
			time = new Date(localStorage.getItem("expirationTime"));
		} catch {}
	}
	return time;
};

export default {
	authenticated: parseNonString(localStorage.getItem("authenticated"), false),
	authProvider:
		localStorage.getItem("authProvider") == null
			? ""
			: localStorage.getItem("authProvider"),
	user: parseNonString(localStorage.getItem("user"), null),
	token:
		localStorage.getItem("token") == null
			? ""
			: JSON.parse(localStorage.getItem("token")),
	tokenExpiration: initTokenExpiration(),
	setup: parseNonString(localStorage.getItem("setup"), false),
	// pauseAuth: parseNonString(localStorage.getItem("pauseAuth"), false),
	// authError:
	// 	localStorage.getItem("authError") == null
	// 		? ""
	// 		: localStorage.getItem("authError"),
	// authErrorTimer: localStorage.getItem("authErrorTimer")
	// 	? new Date(JSON.parse(localStorage.getItem("authErrorTimer")))
	// 	: null,
	// context: parseNonString(localStorage.getItem("context"), null),
	// loginPopup: parseNonString("loginPopup", false), //Shouldn't this be just 'false'?
	// returnUrl:
	// 	localStorage.getItem("returnUrl") == null
	// 		? ""
	// 		: localStorage.getItem("returnUrl"),
	// refreshTimer: localStorage.getItem("refreshTimer")
	// 	? new Date(JSON.parse(localStorage.getItem("refreshTimer")))
	// 	: null,
	// log: initLog(),
	// tenantId:
	// 	localStorage.getItem("tenantId") == null
	// 		? ""
	// 		: localStorage.getItem("tenantId"),
};
