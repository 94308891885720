<template>
  <v-navigation-drawer
    v-model="toggle"
    clipped
    bottom
    app
    width="250px"
    mobile-breakpoint="769"
    class="sidebar"
  >
    <div class="sidebar__title">Campaigns</div>

    <v-list nav class="sidebar__nav">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        link
        exact-path
        :to="item.link"
        color="#4cb2f1"
        class="sidebar__nav--item text-decoration-none"
      >
        <v-list-item-content>
          <v-list-item-title color="#8DABC4">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      items: [
        { title: "Home", icon: "mdi-view-dashboard", link: "/" },
        // {
        //   title: "Treasurer Dashboard",
        //   icon: "mdi-image",
        //   link: "/t-dashboard",
        // },
        // {
        //   title: "Council Member Dashboard",
        //   icon: "mdi-image",
        //   link: "/c-dashboard",
        // },
        { title: "Campaign", icon: "mdi-help-box", link: "/campaign" },
      ],
      toggle: null,
    };
  },
  watch: {
    open(newValue) {
      this.toggle = newValue;
    },
    toggle(newValue) {
      this.$store.commit("app/SET_DRAWER_STATE", newValue);
    },
  },
  mounted() {
    let breakpoint = this.$vuetify.breakpoint;
    if (breakpoint.width <= 768) {
      this.toggle = false;
    }
  },
  computed: {
    ...mapGetters({
      open: "app/getDrawerState",
    }),
  },
};
</script>

<style lang="scss">
.sidebar {
  padding: 25px 10px;
  letter-spacing: 1.7px;
  &__title {
    font-size: 10pt;
    text-transform: uppercase;
    color: #8dabc4 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 25px;
  }
  &__nav {
    padding: 0 16px 0 !important;
    &--item {
      margin: 8px 0;
      padding: 0 !important;
      .v-list-item__content {
        padding: 10px 25px !important;
      }
    }
  }
}
</style>