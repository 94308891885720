<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <NavBar :open="toggle" />
    <SideBar />

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/layout/NavBar";
import SideBar from "@/components/layout/SideBar";

export default {
  name: "app",
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      sidebarFull: true,
      sidebarFullWidth: 250,
      sidebarMinWidth: 0,
      toggle: true,
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    toggleSidebar() {
      return (this.toggle = !this.toggle);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

