<template>
  <v-col class="dashboard-sidebar theme__border__left" cols="12" lg="4">
    <Issues v-if="issues.length" :items="issues" />
    <RecentActivity v-if="recentActivity.length" :items="recentActivity" @voided="voided" />
    <Updates v-if="updates.length" :items="updates"></Updates>
  </v-col>
</template>

<script>
import Issues from "@/components/sub-components/Issues.vue";
import RecentActivity from "@/components/sub-components/RecentActivity.vue";
import Updates from "@/components/sub-components/Updates.vue";

export default {
  components: {
    Issues,
    RecentActivity,
    Updates,
  },
  props: {
    issues: {
      type: Array,
      default: () => [],
    },
    recentActivity: {
      type: Array,
      default: () => [],
    },
    updates: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    voided(id) {
      this.$emit("voided", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-sidebar {
  width: 100%;
  background-color: #ffffff;
  min-height: 0;
  padding: 0 !important;
  @media #{map-get($display-breakpoints,"lg-and-up")} {
    min-height: 100vh;
    /* flex: 0 1 300px; */
  }
}
</style>