<template>
  <v-container fluid>
    <v-fade-transition mode="out-in">
      <SkeletonLoader v-if="loading" key="loader" />

      <v-row v-if="!loading && activeCampaigns.length">
        <v-col cols="12" lg="8" class="px-6">
          <h1 class="welcome">Please choose a Campaign</h1>
          <v-row>
            <v-col>
              <ActiveCampaigns :items="activeCampaigns" class="mx-0"></ActiveCampaigns>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="!loading&& !activeCampaigns.length">
        <v-col cols="12" lg="8" class="px-6 py-6">
          <v-row>
            <v-col class="d-flex">
              <img src="@/assets/sample-client-icon.png" class="campaign-logo" />
              <div>
                <h1 class="mb-0">{{campaginName}}</h1>
                <div class="d-flex">
                  <span>
                    Donation Page:
                    <a class="theme__link" :href="campaginLink">{{campaginLink}}</a>
                  </span>
                  <span class="ml-5">Election Date: {{formatDate(campaginDate)}}</span>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="card p-0 m-0">
                <b-tabs>
                  <b-tab active title="Contributions">
                    <b-table
                      responsive
                      class="theme__table mb-0"
                      :items="filteredContributions"
                      :fields="fields"
                    >
                      <template
                        v-slot:cell(name)="data"
                        class="text-truncate"
                      >{{data.item.firstName}} {{data.item.lastName}}</template>
                      <template v-slot:cell(date)="data">{{formatDate(new Date(data.item.date))}}</template>
                      <template
                        v-slot:cell(amount)="data"
                      >{{$helpers.formatDollars(data.item.amount)}}</template>
                      <template v-slot:cell(disbursed)="data">
                        <span class="theme__table__icon">
                          <b-icon
                            icon="check"
                            v-if="data.item.disbursed==true"
                            alt="Disbursed"
                            title="Disbursed"
                            class="gain"
                          >Disbursed</b-icon>
                          <b-icon
                            icon="x"
                            v-else
                            class="loss"
                            title="Incomplete"
                            alt="Incomplete"
                          >Incomplete</b-icon>
                        </span>
                      </template>
                      <template v-slot:cell(voidButton)="data">
                        <b-button
                          variant="tertiary"
                          style="width: 100px; max-width: 100%"
                          v-if="data.item.canVoid"
                          @click="setDialog(true, 'Void', data.item.transactionId)"
                        >Void</b-button>
                        <b-button
                          variant="tertiary"
                          style="width: 100px; max-width: 100%"
                          v-if="data.item.canRefund"
                          @click="setDialog(true, 'Refund', data.item.transactionId)"
                        >Refund</b-button>
                      </template>
                      <template v-slot:cell(kebab)>
                        <b-dropdown
                          id="dropdown-1"
                          class="kebab"
                          text="Additional Options"
                          variant="tertiary"
                          no-caret
                          offset="-125"
                        >
                          <template #button-content>
                            <b-icon class="kebab" icon="three-dots-vertical" font-scale="1"></b-icon>
                            <span class="sr-only">Additional Options</span>
                          </template>
                          <b-dropdown-item>Resend Thank You</b-dropdown-item>
                          <b-dropdown-item>Resend Receipt</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-table>
                    <v-btn
                      v-if="maxLength < contributions.length"
                      color="primary"
                      class="theme__border__top"
                      @click="maxLength += length"
                      plain
                      tile
                      block
                    >Show More</v-btn>
                  </b-tab>
                  <b-tab title="Disbursement"></b-tab>
                  <b-tab title="All"></b-tab>
                </b-tabs>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="card p-0 m-0">
                <b-tabs class="section-tabs">
                  <b-tab active title="Insights">
                    <b-dropdown
                      id="dropdown-1"
                      style="position: absolute; right: 10px; top: 10px"
                      class="kebab"
                      text="Additional Options"
                      variant="tertiary"
                      no-caret
                      offset="-125"
                    >
                      <template #button-content>
                        <b-icon class="kebab" icon="three-dots-vertical" font-scale="1"></b-icon>
                        <span class="sr-only">Additional Options</span>
                      </template>
                      <b-dropdown-item>Options</b-dropdown-item>
                    </b-dropdown>
                    <!-- <b-form-select
                      v-model="filterGraphselected"
                      style="position: absolute; right: 60px; top: 10px"
                      class="badge-select"
                    >
                      <b-form-select-option :value="12">Last 12 Months</b-form-select-option>
                      <b-form-select-option :value="6">Last 6 Months</b-form-select-option>
                      <b-form-select-option :value="3">Last 3 Months</b-form-select-option>
                    </b-form-select>-->
                    <div class="flex">
                      <div class="graph-metric">
                        <font-awesome-icon icon="money-bill-alt" style="margin:5px;" class="amount"></font-awesome-icon>
                        <div>
                          <span class="value">{{$helpers.formatDollars(amountRaised)}}</span>
                          <br />Amount Raised
                        </div>
                      </div>
                      <div class="graph-metric">
                        <font-awesome-icon icon="recycle" style="margin:5px;" class="donations"></font-awesome-icon>
                        <div>
                          <span class="value">nothing yet</span>
                          <br />Total Donations
                        </div>
                      </div>
                      <div class="graph-metric">
                        <font-awesome-icon icon="user-friends" style="margin:5px;" class="donors"></font-awesome-icon>
                        <div>
                          <span class="value">{{$helpers.formatNumber(totalDonors)}}</span>
                          <br />Total Donors
                        </div>
                      </div>
                    </div>
                    <GChart type="ColumnChart" :data="barChartData" :options="barChartOptions" />
                  </b-tab>
                </b-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <CampaignSidebar
          :totalDonated="monthTotalDonated"
          :totalDonation="monthDonationCount"
          :totalDonors="monthTotalDonors"
          :avgDonation="monthTotalAvgDonation"
        />
      </v-row>
    </v-fade-transition>
    <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-card-title class="headline">Are you sure you would like to {{text}} this transaction?</v-card-title>

        <v-card-text>This is a desctructive action and you will not be able to undo this action.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false">Close</v-btn>
          <v-btn color="red darken-1" text @click="quickChange(text)">{{text}} Label</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SkeletonLoader from "@/components/layout/SkeletonLoader.vue";
import CampaignSidebar from "@/components/layout/CampaignSidebar.vue";
import ActiveCampaigns from "@/components/sub-components/ActiveCampaigns.vue";
import { GChart } from "vue-google-charts";
import axios from "axios";

export default {
  name: "Campaign",
  components: {
    SkeletonLoader,
    CampaignSidebar,
    ActiveCampaigns,
    GChart,
  },
  data() {
    return {
      dialog: false,
      text: "",
      transId: 0,
      loading: true,
      length: 10,
      maxLength: 10,
      activeCampaigns: [],
      campaginName: "John Doe for Senate",
      // campaginDescription: "",
      campaginDate: new Date(2021, 2, 7),
      campaginLink: "www.johndoe2020.com",
      contributions: [],
      donationData: [],
      donorData: [],
      monthTotalDonated: null,
      monthDonationCount: null,
      monthTotalDonors: null,
      monthTotalAvgDonation: null,
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          tdClass: "theme__table__ellipsis text-truncate",
        },
        { key: "date", label: "Date", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "disbursed", label: "Disbursed", sortable: true },
        { key: "voidButton", label: "", thClass: "skinny-col" },
        { key: "kebab", label: "", thClass: "skinny-col" },
      ],
      barChartOptions: {
        height: 300,
        backgroundColor: "#f9f9f9",
        colors: ["#26C1C9", "#81C926", "#FACA00"],
        chartArea: {
          left: 50,
          top: 20,
          right: 20,
          bottom: 50,
          width: "100%",
          height: "100%",
        },
        series: {
          0: {
            type: "bars",
          },
          1: {
            type: "line",
            lineWidth: 0,
          },
          2: {
            type: "line",
          },
        },
        legend: {
          position: "none",
        },
        vAxis: {
          format: "short",
          minorGridlines: { count: 0 },
          baseline: { color: "#e5eef5" },
          gridlines: {
            count: 3,
            color: "#e5eef5",
          },
          textStyle: {
            color: "#8DABC4",
            fontName: "Heebo",
            fontSize: 14,
          },
        },
        hAxis: {
          minorGridlines: { count: 0 },
          gridlines: {
            count: 0,
            color: "#e5eef5",
          },
          textStyle: {
            color: "#8DABC4",
            fontName: "Heebo",
            fontSize: 14,
          },
        },
        tooltip: {
          isHtml: true,
          ignoreBounds: true,
        },
        focusTarget: "category",
      },
      filterGraphselected: 12,
    };
  },
  watch: {
    $route() {
      this.loading = true;
      if (this.$route.query.id) {
        this.setupDashboard(this.$route.query.id);
      } else this.setupDashboard();
    },
  },
  mounted() {
    this.loading = true;
    console.log(this.$route);

    if (!this.$store.getters["auth/getAuthenticated"]) {
      this.login();
    } else if (this.$route.query.id) {
      this.setupDashboard(this.$route.query.id);
    } else {
      this.setupDashboard();
    }
  },
  methods: {
    setDialog(bool, text, id) {
      this.dialog = bool;
      this.text = text;
      this.transId = id;
    },
    login() {
      let url = `${this.$api_url}/admin/login`;

      let req = {
        Username: "smahamud@capitoltechsolutions.com",
        Password: "Test~~1",
      };

      axios.post(url, req).then((res) => {
        this.$store.dispatch("auth/initLogin", res.data);
        // console.log(res);
        this.setupDashboard(this.$route.query.id);
      });
    },
    setupDashboard(id = 0) {
      let url = `${this.$api_url}/admin/accounts/${id}/campaign-dashboard`;
      this.activeCampaigns = [];

      if (id == 0) {
        url = `${this.$api_url}/admin/users/${this.$store.getters["auth/getUser"]}/treasurer-dashboard`;

        axios
          .get(
            url,
            this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
          )
          .then((res) => {
            // console.log(res.data);
            this.activeCampaigns = res.data.campaigns;
            this.loading = false;
          })
          .catch((err) => {
            if (401 == err.response.status) {
              this.$store.dispatch("auth/clearSession");
              this.login();
            }
            console.log("Error when trying to get data", err.response);
          });
      } else {
        axios
          .get(
            url,
            this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
          )
          .then((res) => {
            // console.log(res.data);
            this.campaginName = res.data.accountName;
            this.contributions = res.data.contributions;
            this.donationData = res.data.donationData;
            this.donorData = res.data.donorData;

            this.monthTotalDonated = res.data.monthTotalDonated;
            this.monthDonationCount = res.data.monthDonationCount;
            this.monthTotalDonors = res.data.monthTotalDonors;
            this.monthTotalAvgDonation = res.data.monthAvgDonation;

            this.loading = false;
          })
          .catch((err) => {
            if (401 == err.response.status) {
              this.$store.dispatch("auth/clearSession");
              this.login();
            }
            console.log("Error when trying to get data", err.response);
          });
      }
    },
    quickChange(text) {
      if (text == "Void") {
        let url = `${this.$api_url}/admin/transactions/${this.transId}/void-and-delete`;
        axios
          .post(
            url,
            {},
            this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
          )
          .then((res) => {
            if (res.status == 204) {
              // console.log(res);
              this.setupDashboard(this.$route.query.id);
            }
            this.dialog = false;
          })
          .catch((err) => console.log(err.response));
      }
      if (text == "Refund") {
        let url = `${this.$api_url}/admin/transactions/${this.transId}/refund`;
        axios
          .post(
            url,
            {},
            this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
          )
          .then((res) => {
            if (res.status == 204) {
              // console.log(res);
              this.setupDashboard(this.$route.query.id);
            }
            this.dialog = false;
          })
          .catch((err) => console.log(err.response));
      }
    },

    formatDate(value) {
      var today = new Date();
      var daysSince = (today - value) / (1000 * 60 * 60 * 24);
      if (daysSince < 1) return "just now";
      if (daysSince < 2) return "yesterday";
      var ret = "";
      if (value.getMonth() + 1 < 10) ret = "0";
      ret += value.getMonth() + 1 + "/";
      if (value.getDate() + 1 < 10) ret += "0";
      ret += value.getDate() + 1 + "/";
      ret += value.getYear() % 100;
      return ret;
    },
  },
  computed: {
    filteredContributions() {
      return this.contributions.slice(0, this.maxLength);
    },

    barChartData() {
      let arr = [
        ["Month", "Donations", "Donors"],
        // ["Jan", 1300, 600, 250],
        // ["Feb", 1173, 610, 248],
        // ["Mar", 2134, 613, 530],
        // ["Apr", 462, 587, 119],
        // ["May", 891, 400, 220],
        // ["Jun", 1001, 600, 510],
        // ["Jul", 1716, 590, 546],
        // ["Aug", 1419, 590, 563],
        // ["Sep", 1073, 590, 530],
        // ["Oct", 987, 590, 470],
        // ["Nov", 1563, 590, 546],
        // ["Dec", 2398, 800, 615],
      ];

      this.donorData.forEach((donor, i) => {
        let obj = this.donationData.find(
          (donation) => donation.category == donor.category
        );
        if (obj) {
          arr.push([
            new Date(donor.category).toLocaleString("default", {
              month: "short",
            }),
            obj.value,
            donor.value,
          ]);
        }
      });

      // console.log(data);

      return arr;
    },
    amountRaised() {
      var sum = 0;
      for (var i = 1; i < this.barChartData.length; i++) {
        sum += this.barChartData[i][1];
      }
      return sum;
    },
    totalDonations() {
      var sum = 0;
      for (var i = 1; i < this.barChartData.length; i++) {
        sum += this.barChartData[i][1];
      }
      return sum;
    },
    totalDonors() {
      var sum = 0;
      for (var i = 1; i < this.barChartData.length; i++) {
        sum += this.barChartData[i][2];
      }
      return sum;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  @include margin(16px 4px);
}
.campaign-logo {
  height: 55px;
  width: auto;

  margin-right: 15px;
}

.main-col {
  flex: 1 1 650px;
  min-width: 650px;
}
</style>