/**
 * displays date in localized string 
 * */
export function displayDate(date){
    if (!date){
      return null;
    }
    return new Date(date).toLocaleDateString();
  }
  
  /** 
   * displays shortened date code
   * Will show "just now" for today or "yesterday"
   * Otherwise formats as MM/DD/YY
   * 
   * input: date object
   * */
  export function formatDate(value) {
    if(value==null)
      return "";
    var today=new Date();
    var daysSince = (today-value)/(1000*60*60*24);
    if (daysSince<1)
        return "just now"
    if (daysSince<2)
        return "yesterday"
    var ret ="";
    if(value.getMonth()+1 < 10)
        ret='0';
    ret+= (value.getMonth()+1) + '/';
    if(value.getDate()+1 < 10)
        ret+='0';
    ret+=(value.getDate()+1) + '/';
    ret+=value.getYear()%100;
    return ret;
  
  }
  /**
   * Formats dollars as $#,###.## 
   * Set deciamls to true to format as $#,###
   * Will not display decimals for values over $1000
   * 
   * @param {float} value 
   * @param {bool} decimals 
   */
  export function formatDollars(value, decimals=true) {
    if (value == null)
      return null;
    let str = "$";
    if (value < 0){
        str = "-$";
    }
    var decimalPoints=0;
    if(value<1000&&decimals){
        decimalPoints = 2;
    }
    return str += Math.abs(value).toFixed(decimalPoints).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      
  }

  /**
   * Formats number with delimiter
   * Change second parameter to change default delimiter
   * 
   * @param {float} value 
   * @param {character} delimiter 
   */
  export function formatNumber(value, delimiter=',') {
    if (value == null)
      return null;
    return Math.abs(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
      
  }

  /**
   * Displays time as 'just now', minutes, hours, or days, 
   * whichever is most appropriate
   * 
   * @param {date} date 
   */
  export function displayTime(date){
      if (!date)
        return "";
      var time = Math.floor((new Date() - date)/1000/60); //minutes
      if (time<1)
          return "just now";
      if (time<60)
          return time + " min";
      time = Math.floor(time/60); //hours now
      if (time<24)
          return time +" hr";
      time = Math.floor(time/24) //days now
      return time+ " days";
  }