<template>
  <v-col class="campaign-sidebar" cols="12" lg="4">
    <v-row>
      <v-col>
        <div class="card mx-0">
          <router-link class="btn-tertiary" to>
            <font-awesome-icon icon="ticket-alt"></font-awesome-icon>Events/Fundraising Campaigns
          </router-link>
          <router-link class="btn-tertiary" to>
            <font-awesome-icon icon="sync-alt"></font-awesome-icon>Recurring Donations
          </router-link>
          <router-link class="btn-tertiary" to>
            <font-awesome-icon icon="copy"></font-awesome-icon>Page Design
          </router-link>
          <router-link class="btn-tertiary" to>
            <font-awesome-icon icon="users"></font-awesome-icon>User Management
          </router-link>
          <router-link class="btn-tertiary" to>
            <font-awesome-icon icon="cog"></font-awesome-icon>Account Settings
          </router-link>
          <router-link class="btn-tertiary" to>
            <font-awesome-icon icon="box"></font-awesome-icon>Bulk Import
          </router-link>
        </div>
      </v-col>
    </v-row>

    <v-divider />

    <span class="label">This Month</span>
    <v-row>
      <!-- <v-col>
        <MetricCard
          label="Total Pending"
          icon="hourglass-half"
          :displayAsMoney="true"
          :value="3135"
        />
      </v-col>-->
      <v-col>
        <MetricCard
          label="Total Donated"
          icon="coins"
          :value="totalDonated.value"
          :prevValue="totalDonated.changeRate"
          prevValuePercentage
          displayAsMoney
          displayMonthCompare
        />
      </v-col>
      <v-col>
        <MetricCard
          label="Total # of Donations"
          icon="money-check-alt"
          :value="totalDonation.value"
          :prevValue="totalDonation.changeRate"
          prevValuePercentage
          displayMonthCompare
        />
      </v-col>
      <v-col>
        <MetricCard
          label="# of Donors"
          icon="users"
          color="#81C926"
          :value="totalDonors.value"
          :prevValue="totalDonors.changeRate"
          prevValuePercentage
          displayMonthCompare
        />
      </v-col>
      <v-col>
        <MetricCard
          label="Average Donation Size"
          icon="money-check-alt"
          color="#26C1C9"
          :value="avgDonation.value"
          :prevValue="avgDonation.changeRate"
          prevValuePercentage
          displayMonthCompare
          displayAsMoney
        ></MetricCard>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <div class="card p-0 mx-0">
          <b-tabs class="section-tabs">
            <b-tab active title="Payment Method">
              <div class="centered-overlay">
                <span class="value">{{$helpers.formatNumber(total(pieChartData))}}</span>
                <br />Donations
              </div>
              <GChart type="PieChart" :data="pieChartData" :options="pieChartOptions" />
            </b-tab>
          </b-tabs>
        </div>
      </v-col>
    </v-row>-->
  </v-col>
</template>

<script>
import MetricCard from "@/components/cards/MetricCard.vue";
import { GChart } from "vue-google-charts";

export default {
  components: {
    MetricCard,
    GChart,
  },
  props: {
    totalDonated: {
      type: Object,
      default: {
        value: 0,
        changeRate: 0,
      },
    },
    totalDonation: {
      type: Object,
      default: {
        value: 0,
        changeRate: 0,
      },
    },
    totalDonors: {
      type: Object,
      default: {
        value: 0,
        changeRate: 0,
      },
    },
    avgDonation: {
      type: Object,
      default: {
        value: 0,
        changeRate: 0,
      },
    },
  },
  data() {
    return {
      pieChartData: [
        ["Payment Type", "Amount"],
        ["Paypal/CashApp/Venmo", 300],
        ["Credit Card", 800],
        ["Apple Pay", 2000],
        ["Checks", 250],
      ],
      pieChartOptions: {
        pieHole: 0.75,
        colors: ["#26C1C9", "#FACA00", "#AB7DF6", "#81C926"],
        backgroundColor: "#f9f9f9",
        height: 280,
        chartArea: {
          left: 10,
          top: 10,
          right: 10,
          bottom: 90,
          width: "100%",
          height: "100%",
        },
        legend: {
          position: "bottom",
          maxLines: "2",
          alignment: "center",
          textStyle: {
            color: "#8DABC4",
            fontName: "Heebo",
            fontSize: 12,
          },
        },
        pieSliceText: "none",
        tooltip: {
          isHtml: true,
        },
      },
    };
  },
  methods: {
    total(data) {
      var sum = 0;
      for (var i = 1; i < data.length; i++) {
        sum += data[i][1];
      }
      return sum;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-sidebar {
  // & > * {
  //   width: 100%;
  //   margin-bottom: 10px;
  // }
  @media #{map-get($display-breakpoints,"lg-and-up")} {
    min-height: 100vh;
  }
}
</style>