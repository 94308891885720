<template>
  <v-card class="metric-card" flat>
    <div class="label">{{label}}</div>
    <div v-if="displayKebab">
      <b-dropdown
        id="dropdown-1"
        class="kebab"
        text="Additional Options"
        variant="tertiary"
        no-caret
        offset="-125"
      >
        <template #button-content>
          <b-icon class="kebab" icon="three-dots-vertical" font-scale="1"></b-icon>
          <span class="sr-only">Additional Options</span>
        </template>
        <b-dropdown-item>Edit</b-dropdown-item>
        <b-dropdown-item>View</b-dropdown-item>
      </b-dropdown>
    </div>

    <p class="value">
      <span :style="'color: '+color">
        <font-awesome-icon :icon="icon">{{label}}</font-awesome-icon>
      </span>
      <span v-if="displayAsPercentage">{{$helpers.formatNumber(percentage)}}%</span>
      <span v-else-if="displayAsMoney">{{$helpers.formatDollars(value)}}</span>
      <span v-else>{{$helpers.formatNumber(value)}}</span>
    </p>
    <div>
      <b-progress v-if="displayProgressBar" :class="'custom-progress-bar'" :max="progressMax">
        <b-progress-bar :style="'background-color: '+color" :value="progressCurrent"></b-progress-bar>
      </b-progress>
    </div>

    <!--
        <div v-if="positiveReviews != 0 || negativeReviews != 0">
            <font-awesome-icon icon="hand-thumbs-up">Positive Reviews</font-awesome-icon>
            {{$helpers.formatNumber(positiveReviews)}}
            <font-awesome-icon icon="hand-thumbs-down">Negative Reviews</font-awesome-icon>
            {{$helpers.formatNumber(negativeReviews)}}
    </div>-->
    <div if="displayMonthCompare">
      <span v-if="prevCompare>0" class="gain">
        <font-awesome-icon icon="arrow-up">Gain</font-awesome-icon>
        +{{prevCompare}}%
      </span>
      <span v-else-if="prevCompare<0" class="loss">
        <font-awesome-icon icon="arrow-down">Loss</font-awesome-icon>
        {{prevCompare}}%
      </span>
      <span v-else>{{prevCompare}}%</span>
      than last month
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MetricCard",
  props: {
    icon: { type: String, default: "plus-circle" },
    color: { type: String, default: "#5D9B64" },
    label: { type: String, default: "Total" },
    value: { type: Number, default: 0 },
    prevValue: { type: Number, default: 0 },
    //positiveReviews: {type:Number, default: 0},
    //negativeReviews: {type:Number, default: 0},
    prevValuePercentage: { type: Boolean, default: false },
    displayAsPercentage: { type: Boolean, default: false },
    displayKebab: { type: Boolean, default: false },
    displayAsMoney: { type: Boolean, default: false },
    displayMonthCompare: { type: Boolean, default: false },
    displayProgressBar: { type: Boolean, default: false },
  },
  data() {
    return {
      progressMax: 0,
      progressCurrent: 0,
    };
  },
  mounted() {
    this.progressCurrent = this.value;
    if (this.displayAsPercentage) {
      this.progressMax = 100;
      this.progressCurrent = this.percentage;
    } else if (this.value > this.prevValue) this.progressMax = this.value;
    this.progressMax = this.prevValue;
  },
  computed: {
    prevCompare() {
      if (this.prevValuePercentage) return Math.floor(this.prevValue * 100);
      return Math.floor(((this.value - this.prevValue) / this.prevValue) * 100);
    },
    percentage() {
      /*
        if (this.positiveReviews != 0 || this.negativeReviews != 0) {
            return Math.floor((this.positiveReviews/(this.positiveReviews+this.negativeReviews))*100);
        }
        else 
        */
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.metric-card {
  width: 100%;
  padding: 15px;
  flex: 1 1 275px;
  min-width: 210px;
  border: 1px solid #b7d2e5 !important;
}

.metric-card .kebab,
.top-right {
  position: absolute;
  right: 0px;
  top: 5px;
  height: 30px;
  width: 45px;
  padding: 3px;
  overflow: visible;
}

.metric-card .kebab > button {
  height: 30px;
  width: 45px;
  padding: 3px;
  overflow: visible;
}

.metric-card .label {
  font-size: 11pt;
  line-height: 17px;
}

.progress {
  height: 6px;
  margin-bottom: 10px;
}
</style>