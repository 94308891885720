<template>
  <v-container fluid style="padding:0">
    <!--
        <b-navbar class="action-nav-bar">
            <b-navbar-nav>
                <b-nav-item>
                    <router-link to="">Link</router-link>
                </b-nav-item><b-nav-item>
                    <router-link to="">Link</router-link>
                </b-nav-item><b-nav-item>
                    <router-link to="">Link</router-link>
                </b-nav-item>
                <b-nav-item>
                    <b-dropdown id="dropdown-1" class="kebab" text="More" variant="tertiary" no-caret offset="-125">
                        <template #button-content>
                            <font-awesome-icon class="kebab" icon="ellipsis-h" font-scale="1"></font-awesome-icon><span class="sr-only">More</span>
                        </template>
                        <b-dropdown-item>Link</b-dropdown-item>
                        <b-dropdown-item>Link</b-dropdown-item>
                        <b-dropdown-item>Link</b-dropdown-item>
                    </b-dropdown>
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item>
                    <b-button variant="tertiary">Action</b-button>
                </b-nav-item><b-nav-item>
                    <b-button variant="tertiary">Action</b-button>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    -->
    <v-row>
      <v-col class="main-col">
        <div class="card p-0" v-if="!donationsHidden">
          <b-tabs class="section-tabs">
            <b-tab active title="Donations Across Campaigns">
              <b-button
                class="top-right kebab"
                text="Close"
                style="top:11px"
                variant="tertiary"
                @click="donationsHidden=!donationsHidden"
              >
                <b-icon class="kebab" icon="x" font-scale="2"></b-icon>
                <span class="sr-only">Close</span>
              </b-button>
              <b-button
                class="top-right kebab"
                style="right:50px; top:11px"
                text="Show/Hide"
                variant="tertiary"
                @click="donationsMin=!donationsMin"
              >
                <b-icon class="kebab" :icon="donationsMin? 'plus' : 'dash'" font-scale="2"></b-icon>
                <span class="sr-only">Show/Hide</span>
              </b-button>
              <div v-if="!donationsMin">
                <div class="flex">
                  <!--for each column in chart, display custom key-->
                  <div
                    class="graph-metric"
                    v-for="(col, i) in barChartData[0].slice(1,barChartData[0].length-1)"
                    :key="i"
                  >
                    <b-icon icon="circle-fill" :style="'color: ' + seriesColors[i]"></b-icon>
                    {{col}}
                  </div>
                </div>
                <GChart type="ColumnChart" :data="barChartDataFiltered" :options="barChartOptions" />
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="flex" style="flex-wrap: wrap; justify-content: center">
          <MetricCard
            label="Active Campagins"
            icon="flag"
            color="#AB7DF6"
            :value="7"
            :prevValue="8"
            :displayKebab="true"
            :displayMonthCompare="true"
          ></MetricCard>
          <MetricCard
            label="Top Donation"
            icon="trophy"
            color="#81C926"
            :value="3500"
            :prevValue="3043"
            :displayKebab="true"
            :displayMonthCompare="true"
            :displayAsMoney="true"
          ></MetricCard>
          <MetricCard
            label="Average Donation Size"
            icon="money-check-alt"
            color="#26C1C9"
            :value="18.75"
            :prevValue="17.60"
            :displayKebab="true"
            :displayMonthCompare="true"
            :displayAsMoney="true"
          ></MetricCard>
        </div>
        <ActiveCampaigns :items="activeItems"></ActiveCampaigns>
        <div class="flex" style="justify-content: space-evenly;  flex-wrap: wrap"></div>
      </v-col>
      <v-col class="dashboard-sidebar">
        <Updates :updates="updates"></Updates>
        <RecentActivity :activities="activities"></RecentActivity>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MetricCard from "@/components/cards/MetricCard.vue";
import ActiveCampaigns from "@/components/sub-components/ActiveCampaigns.vue";
import RecentActivity from "@/components/sub-components/RecentActivity.vue";
import Updates from "@/components/sub-components/Updates.vue";
import PieChart from "@/components/sub-components/PieChart.vue";
import { GChart } from "vue-google-charts";
export default {
  name: "MemberDashboard",
  components: {
    MetricCard,
    ActiveCampaigns,
    RecentActivity,
    Updates,
    PieChart,
    GChart,
  },
  props: {
    seriesColors: {
      type: Array,
      default: function () {
        return ["#AB7DF6", "#26C1C9", "#81C926", "#FACA00"];
      },
    },
  },
  data() {
    return {
      donationsMin: false,
      donationsHidden: false,
      /* sample data */
      activeItems: [
        {
          name: "Jane Doe for Senate",
          donators: 354,
          recurring: 85,
          total: 555108,
          average: 17.85,
        },
        {
          name: "John Doe for Senate",
          donators: 124,
          recurring: 29,
          total: 105855,
          average: 24.85,
        },
        {
          name: "NRCC",
          donators: 551,
          recurring: 177,
          total: 755055,
          average: 17.85,
        },
        {
          name: "Scott Bell Montana",
          donators: 200,
          recurring: 45,
          total: 315502,
          average: 9.85,
        },
      ],
      barChartData: [
        ["Month", "2020", "2019"],
        ["Jan", 23225, 0],
        ["Feb", 21000, 0],
        ["Mar", 24500, 0],
        ["Apr", 23800, 0],
        ["May", 18000, 0],
        ["Jun", 22000, 0],
        ["Jul", 23225, 8221],
        ["Aug", 21000, 6000],
        ["Sep", 24500, 10000],
        ["Oct", 23225, 11000],
        ["Nov", 24500, 12000],
        ["Dec", 23225, 18000],
      ],
      barChartOptions: {
        height: 300,
        backgroundColor: {
          fill: "#f9f9f9",
          fillOpacity: 0,
        },
        colors: this.seriesColors,
        chartArea: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 50,
          width: "100%",
          height: "100%",
        },
        series: {
          0: {
            type: "bars",
          },
          1: {
            type: "bars",
          },
          2: {
            type: "line",
            lineWidth: 0,
          },
        },
        legend: {
          position: "none",
        },
        vAxis: {
          format: "short",
          minorGridlines: { count: 0 },
          baseline: { color: "#e5eef5" },
          gridlines: {
            count: 4,
            color: "#e5eef5",
          },
          textStyle: {
            color: "#8DABC4",
            opacity: 0,
            fontName: "Heebo",
            fontSize: 0,
          },
        },
        hAxis: {
          minorGridlines: { count: 0 },
          gridlines: {
            color: "#e5eef5",
          },
          textStyle: {
            color: "#8DABC4",
            fontName: "Heebo",
            fontSize: 14,
          },
        },
        tooltip: {
          isHtml: true,
          ignoreBounds: true,
        },
        focusTarget: "category",
      },
      activities: [
        {
          donor: "Jane Doe",
          campaign: "Jake Smith",
          date: new Date(2021, 0, 4, 16, 30, 0),
          amount: 25,
          recurring: false,
        },
        {
          donor: "Christopher Watts",
          campaign: "Susan McAnally",
          date: new Date(2021, 0, 4, 15, 15, 0),
          amount: 15,
          recurring: false,
        },
        {
          donor: "Erica West",
          campaign: "Jane Doe for Senate",
          date: new Date(2021, 0, 4, 15, 15, 0),
          amount: 15,
          recurring: false,
        },
        {
          donor: "Joseph Mahale",
          campaign: "Jane Doe for Senate",
          date: new Date(2021, 0, 4, 15, 15, 0),
          amount: 150,
          recurring: false,
        },
        {
          donor: "Jake Elia",
          campaign: "NRCC",
          date: new Date(2021, 0, 4, 15, 15, 0),
          amount: 15,
          recurring: true,
        },
        {
          donor: "Erica West",
          campaign: "Jane Doe for Senate",
          date: new Date(2021, 0, 4, 15, 15, 0),
          amount: 15,
          recurring: false,
        },
        {
          donor: "Joseph Mahale",
          campaign: "Jane Doe for Senate",
          date: new Date(2021, 0, 4, 15, 15, 0),
          amount: 150,
          recurring: false,
        },
      ],
      updates: [
        {
          name: "Recieve donations by Apple Pay",
          campaign: "eFundraising",
          date: new Date(2021, 0, 4, 16, 30, 0),
        },
        {
          name: "New landing page template designs",
          campaign: "eFundraising",
          date: new Date(2021, 0, 4, 15, 15, 0),
        },
        {
          name: "New features released",
          campaign: "eFundraising",
          date: new Date(2021, 0, 4, 15, 15, 0),
        },
      ],
      /* end sample data */
    };
  },
  computed: {
    /**
     * appends percentage increased from last 2 years
     * on new col in data series
     */
    barChartDataFiltered() {
      var delta,
        row = [],
        array = [];
      row = this.barChartData[0];
      row.push("Percentage change");
      array.push(row);
      for (var i = 1; i < this.barChartData.length; i++) {
        row = this.barChartData[i];
        delta = 0;
        if (row[2] != 0 && row[1] != 0) {
          delta = Math.floor(((row[1] - row[2]) / row[2]) * 100);
        }
        row.push(delta);
        array.push(row);
      }
      return array;
    },
  },
};
</script>

