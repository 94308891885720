import Vue from "vue";
import App from "./App.vue";
import Router from "vue-router";
import { BootstrapVueIcons, BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "./assets/style.css";

import config from "./config";

Vue.config.productionTip = true;

import routes from "@/routes";

import vuetify from "@/plugins/vuetify";
import * as auth from "@/plugins/auth";
import Vuex from "vuex";
import store from "@/store";

import * as helpers from "./helpers.js";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPaperPlane,
	faMoneyBillAlt,
	faRecycle,
	faUserFriends,
	faCoins,
	faHourglassHalf,
	faUsers,
	faMoneyCheckAlt,
	faSearch,
	faTicketAlt,
	faSyncAlt,
	faCopy,
	faCog,
	faBox,
	faPlus,
	faPlusCircle,
	faFlag,
	faBars,
	faArrowUp,
	faArrowDown,
	faTimes,
	faEllipsisV,
	faEllipsisH,
	faDollarSign,
	faClipboardList,
	faTrophy,
	faCaretUp,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faIdBadge,
	faComments,
	faDownload,
	faShoppingCart,
	faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
library.add(
	faPaperPlane,
	faMoneyBillAlt,
	faRecycle,
	faUserFriends,
	faCoins,
	faHourglassHalf,
	faUsers,
	faMoneyCheckAlt,
	faSearch,
	faTicketAlt,
	faSyncAlt,
	faCopy,
	faCog,
	faBox,
	faPlus,
	faPlusCircle,
	faFlag,
	faBars,
	faArrowUp,
	faArrowDown,
	faTimes,
	faEllipsisV,
	faEllipsisH,
	faDollarSign,
	faClipboardList,
	faTrophy,
	faCaretUp,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faIdBadge,
	faComments,
	faDownload,
	faShoppingCart,
	faCalendarAlt
);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);

const helperPlugin = {
	install() {
		Vue.helpers = helpers;
		Vue.prototype.$helpers = helpers;
		Vue.auth = auth;
		Vue.prototype.$auth = auth;
		Vue.prototype.$api_url = config.apiUrl;
	},
};
Vue.use(helperPlugin);

Vue.use(Vuex);

Vue.use(Router);
let router = new Router(routes);

new Vue({
	vuetify,
	store,
	router: router,
	render: (h) => h(App),
}).$mount("#app");
