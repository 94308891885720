import axios from "axios";

export default {
	voidTrans(ctx, payload) {
		let url = `${this.$api_url}/admin/transactions/${this.transId}/void-and-delete`;
		axios
			.post(
				url,
				{},
				this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
			)
			.then((res) => {
				if (res.status == 204) {
					// console.log(res);
					this.$emit("voided", this.transId);
				}
				this.dialog = false;
			})
			.catch((err) => console.log(err.response));
	},
	refundTrans(ctx, payload) {
		let url = `${this.$api_url}/admin/transactions/${this.transId}/refund`;
		axios
			.post(
				url,
				{},
				this.$auth.getReqConfig(this.$store.getters["auth/getToken"])
			)
			.then((res) => {
				if (res.status == 204) {
					// console.log(res);
					this.$emit("voided", this.transId);
				}
				this.dialog = false;
			})
			.catch((err) => console.log(err.response));
	},
};
