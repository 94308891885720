<template>
  <div class="card p-0 m-0">
    <h2>Active Campaigns</h2>
    <!-- <v-data-table :items="items" :headers="headers" class="theme__table" hide-default-footer>
      <template #item.kebab>
        <v-menu transition="slide-y-transition" :nudge-width="100" :nudge-left="175" offset-x>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="#a8c6df">fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item-group>
              <v-list-item link>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>-->
    <b-table
      hover
      responsive
      class="theme__table pointer mb-0"
      :items="filteredItems"
      :fields="fields"
      @row-clicked="rowClicked"
    >
      <template
        v-slot:cell(totalDonationAmount)="data"
      >{{$helpers.formatDollars(data.item.totalDonationAmount)}}</template>

      <template
        v-slot:cell(average)="data"
      >{{$helpers.formatDollars(data.item.totalDonationAmount / data.item.donorCount)}}</template>

      <template v-slot:cell(kebab)>
        <b-dropdown
          id="dropdown-1"
          class="kebab"
          text="Additional Options"
          variant="tertiary"
          no-caret
          offset="-125"
        >
          <template #button-content>
            <b-icon class="kebab" icon="three-dots-vertical" font-scale="1"></b-icon>
            <span class="sr-only">Additional Options</span>
          </template>
          <b-dropdown-item>Edit</b-dropdown-item>
          <b-dropdown-item>View</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <v-btn
      v-if="maxLength < items.length "
      color="primary"
      class="theme__border__top"
      @click="maxLength += length"
      plain
      tile
    >Show More</v-btn>
  </div>
</template>

<script>
export default {
  name: "ActiveCampaigns",
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      campaginId: "",
      campaginName: "John Doe for Senate",
      campaginDescription: "",
      length: 10,
      maxLength: 10,
      fields: [
        {
          key: "accountName",
          label: "Name",
          sortable: true,
          tdClass: "theme__table__ellipsis text-truncate",
        },
        { key: "donorCount", label: "Donators", sortable: true },
        { key: "totalDonationAmount", label: "Total", sortable: true },
        { key: "average", label: "Avg. Donation", sortable: true },
        // { key: "kebab", label: "", thClass: "skinny-col" },
      ],
      // headers: [
      //   { value: "name", text: "Name", sortable: true },
      //   { value: "donators", text: "Donators", sortable: true },
      //   { value: "total", text: "Total", sortable: true },
      //   { value: "average", text: "Ave. Donation", sortable: true },
      //   { value: "kebab", text: "", sortable: false },
      // ],
    };
  },
  methods: {
    average(data) {
      return data.item.total / data.item.donators;
    },
    rowClicked(record, index) {
      this.$router.push("/campaign/" + record.accountId);
    },
  },
  computed: {
    filteredItems() {
      return this.items.slice(0, this.maxLength);
    },
  },
};
</script>